import { Typography, Tag } from 'antd'
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, useSortable, SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable'

const { Text } = Typography

const SortableTag = ({ id, name, onClose }) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

	const style = {
		padding: 8,
		cursor: 'pointer',
		useSelect: 'none',
		transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
		transition: transition ?? undefined
	}

	return (
		<Tag className='sortable-tag' ref={setNodeRef} style={style} {...attributes} {...listeners} closable onClose={onClose}>
			<Text copyable={false}>{name}</Text>
		</Tag>
	)
}

const containerStyle = {
	gap: 8,
	display: 'flex',
	flexDirection: 'column'
}

const DraggableContentTagsColumn = ({ value, onChange, blocks, onTagClose = () => {} }) => {
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 15
			}
		})
	)

	const onDragEnd = ({ active, over }) => {
		if (active.id !== over.id) onChange(arrayMove(value, value.indexOf(active.id), value.indexOf(over.id)))
	}

	return (
		<DndContext onDragEnd={onDragEnd} collisionDetection={closestCenter} sensors={sensors} coordinates={sortableKeyboardCoordinates}>
			<SortableContext items={value} strategy={verticalListSortingStrategy}>
				<div style={containerStyle}>
					{value.map(id => {
						const block = blocks.find(b => b.id === id)
						return (
							<SortableTag
								key={id}
								id={id}
								name={block?.name || ''}
								onClose={() => {
									onTagClose(id)
									onChange(value.filter(v => v !== id))
								}}
							/>
						)
					})}
				</div>
			</SortableContext>
		</DndContext>
	)
}

export default DraggableContentTagsColumn
