import { green } from '@ant-design/colors'

const schema = {
	name: { required: true },
	target_market: { renderType: 'text', sorter: {} },
	industry_names: { title: 'Industries', renderType: 'industries', renderSchema: green[5], formField: 'industry_ids' },
	updated: { renderType: 'date', showInputWhen: false, sorter: {} }
}

export default schema
