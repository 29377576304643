import { SchematicDataTableWithCreateModal } from '../../components/schematicComponents'

import schema from './schema'

const ProjectReportsList = () => (
	<SchematicDataTableWithCreateModal
		editable
		schema={schema}
		actions={{ del: true }}
		title='Project reports'
		resource='project_reports'
		dataTableProps={{ search: true }}
	/>
)

export default ProjectReportsList
