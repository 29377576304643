import { useGetIdentity } from '@refinedev/core'
import { Avatar, Layout as AntdLayout, Space, Typography } from 'antd'

const { Text } = Typography

export const Header = () => {
	const { data: user } = useGetIdentity()

	return (
		<AntdLayout.Header
			style={{
				backgroundColor: '#f5f5f5',
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
				padding: '0px 24px',
				height: '64px'
			}}
		>
			<Space>
				<Space style={{ marginLeft: '8px' }} size='middle'>
					{user?.name && <Text strong>{user.name}</Text>}
					{user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
				</Space>
			</Space>
		</AntdLayout.Header>
	)
}
