import { FileExcelOutlined } from '@ant-design/icons'
import { useApiUrl } from '@refinedev/core'
import { Button } from 'antd'

const ExportAudienceButton = ({ campaignId }) => {
	const apiUrl = useApiUrl()

	const exportAudienceHandler = async camp_id => {
		try {
			const token = localStorage.getItem('token')

			const response = await fetch(`${apiUrl}/campaigns/${camp_id}/export_audience`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			const blob = await response.blob()

			const url = window.URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'investors.xlsx')

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)

			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error('Error downloading file', error)
		}
	}

	return (
		<Button icon={<FileExcelOutlined />} onClick={() => exportAudienceHandler(campaignId)}>
			Export audience
		</Button>
	)
}

export default ExportAudienceButton
