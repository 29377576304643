import { Form, Row } from 'antd'

import FilterInputColumn from './wrappers/filterInputColumn'
import { GroupChatsSelector } from '../../selectors/querySelectors'

const ChatFilter = ({ pathName, itemProps }) => (
	<Row gutter={[16, 16]}>
		<FilterInputColumn title='User at chat' span={6}>
			<Form.Item {...itemProps} name={[...pathName, 'group_chat_id']} rules={[{ required: true }]}>
				<GroupChatsSelector />
			</Form.Item>
		</FilterInputColumn>
	</Row>
)

export default ChatFilter
