import { DateField, TextField } from '@refinedev/antd'
import { Button, Col, Divider, Row, Tag, Typography } from 'antd'
import { useApiUrl, useCustomMutation, useGo } from '@refinedev/core'

import { tgName } from '../../components/other'
import { DataShow } from '../../components/dataView'

const { Title, Text } = Typography

const AgreementShow = () => {
	const apiUrl = useApiUrl()
	const go = useGo()

	const { mutate } = useCustomMutation()

	return (
		<DataShow>
			{record => {
				const createInvoice = () =>
					mutate(
						{
							url: `${apiUrl}/agreements/${record.id}/invoice`,
							method: 'post'
						},
						{
							onSuccess: data =>
								go({
									to: `/invoices/show/${data.data}`,
									type: 'push'
								})
						}
					)

				return (
					<>
						<Title>
							{record.investor_first_name} {record.investor_last_name}
						</Title>
						<Text code copyable>
							{tgName(record.investor_tg_username)}
						</Text>
						<Divider />
						<Row>
							<Col span={4}>Agreement No:</Col>
							<Col span={4}>
								<TextField value={record.number} />
							</Col>
							<Col span={4}>Dated on:</Col>
							<Col span={4}>
								<DateField value={record.date} />
							</Col>
							<Col span={2}>{record.active ? <Tag color='green'>Active</Tag> : <Tag color='gray'>Inactive</Tag>}</Col>
							<Col span={2}>
								<Button size='small' disabled={record.active}>
									Activate
								</Button>
							</Col>
						</Row>
						<Divider />
						<Row>
							<Col span={4}>Amount:</Col>
							<Col span={4}>
								<TextField value={record.amount} />
							</Col>
							<Col span={4}>
								<Button disabled={!record.active} onClick={createInvoice}>
									Create invoice
								</Button>
							</Col>
						</Row>
					</>
				)
			}}
		</DataShow>
	)
}

export default AgreementShow
