import { useSelect } from '@refinedev/antd'
import { Col, Row, Select, Typography } from 'antd'

import { tgName } from '../other'

const { Text } = Typography

const defaultResourcesFactory = data => {
	const d = data.data || data
	return d?.rows || d
}

const EntitySelector = ({
	resourcesFactory = defaultResourcesFactory,
	label,
	onChange,
	resource,
	nameFactory,
	placeholder,
	defaultValue,
	...props
}) => {
	const { selectProps } = useSelect({
		resource,
		debounce: 500,
		optionLabel: 'name',
		defaultValue: defaultValue || props?.value,
		onSearch: value => [
			{
				field: 'search',
				value
			}
		],
		queryOptions: {
			select: data => ({
				...data,
				data: resourcesFactory(data).map(o => ({ ...o, name: nameFactory ? nameFactory(o) : o.name }))
			})
		}
	})

	return (
		<Select
			label={label}
			onChange={onChange}
			style={{ width: '100%' }}
			placeholder={placeholder}
			defaultValue={defaultValue}
			{...selectProps}
			{...props}
		/>
	)
}

export const InvestorSelector = ({ defaultValue, onChange, ...props }) => (
	<EntitySelector
		label='Investor'
		defaultValue={defaultValue}
		onChange={onChange}
		resource='investors'
		placeholder='Select investor'
		nameFactory={inv => (
			<Row>
				<Col flex='auto'>
					{inv.first_name || inv.alt_first_name1} {inv.last_name || inv.alt_last_name1}
				</Col>
				<Col>
					<Text code>{tgName(inv.tg_username)}</Text>
				</Col>
			</Row>
		)}
		{...props}
	/>
)

export const DealSelector = ({ defaultValue, onChange, ...props }) => (
	<EntitySelector
		label='Deal'
		defaultValue={defaultValue}
		onChange={onChange}
		resource='deals'
		placeholder='Select deal'
		nameFactory={d => `${d.name} ${d.number}`}
		{...props}
	/>
)

export const ProjectSelector = ({ defaultValue, onChange, ...props }) => (
	<EntitySelector
		label='Project'
		defaultValue={defaultValue}
		onChange={onChange}
		resource='projects'
		placeholder='Select project'
		nameFactory={d => d.name}
		{...props}
	/>
)

export const CampaignSelector = ({ defaultValue, onChange, ...props }) => (
	<EntitySelector
		label='Campaign'
		defaultValue={defaultValue}
		onChange={onChange}
		resource='campaigns'
		placeholder='Select campaign'
		nameFactory={d => d.name}
		{...props}
	/>
)

export const MeetupSelector = ({ defaultValue, onChange, ...props }) => (
	<EntitySelector
		label='Meetup'
		defaultValue={defaultValue}
		onChange={onChange}
		resource='meetups'
		placeholder='Select meetup'
		nameFactory={d => d.name}
		{...props}
	/>
)

export const IndustrySelector = ({ defaultValue, onChange, ...props }) => (
	<EntitySelector
		label='Industry'
		defaultValue={defaultValue}
		onChange={onChange}
		resource='industries'
		placeholder='Select industries'
		nameFactory={d => d.name}
		mode='multiple'
		{...props}
	/>
)

export const LegalEntitySelector = ({ defaultValue, onChange, ...props }) => (
	<EntitySelector
		label='Legal entity'
		defaultValue={defaultValue}
		onChange={onChange}
		resource='legal_entities'
		placeholder='Select legal entity'
		nameFactory={d => d.name}
		{...props}
	/>
)
