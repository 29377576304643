import { Button, Form, Steps } from 'antd'

const StepsForm = ({ formProps, current, gotoStep, stepsProps, items }) => (
	<Form {...formProps}>
		<Steps {...stepsProps} current={current} items={items} />
		<div style={{ marginTop: 24 }}>{items[current].content}</div>
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				marginTop: 24
			}}
		>
			<div>{current > 0 && <Button onClick={() => gotoStep(current - 1)}>Previous</Button>}</div>
			<div>
				{current < items.length - 1 && (
					<Button type='primary' onClick={() => gotoStep(current + 1)}>
						Next
					</Button>
				)}
			</div>
		</div>
	</Form>
)

export default StepsForm
