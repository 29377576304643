import { SchematicDataTableWithCreateModal } from '../../components/schematicComponents'

import schema from './schema'

const InvestEventsList = () => (
	<SchematicDataTableWithCreateModal
		editable
		schema={schema}
		title='Invest Event'
		resource='invest_events'
		dataTableProps={{ search: true }}
	/>
)

export default InvestEventsList
