import { Button } from 'antd'

import { DataTable, renderers } from '../../components/dataView'

const columns = [
	{ field: 'created', title: 'Created', render: renderers.time },
	{ field: 'agreement_number', title: 'Ag. No', render: renderers.text },
	{ field: 'investor_first_name', title: 'First name', render: renderers.text },
	{ field: 'investor_last_name', title: 'Last name', render: renderers.text },
	{ field: 'investor_tg_username', title: 'TG name', render: renderers.username },
	{ field: 'amount', title: 'Amount', render: renderers.number },
	{ field: 'detail', title: 'Details', render: renderers.text },
	{
		field: 'url',
		title: 'Link',
		render: v => (
			<Button type='link' href={v}>
				Pay
			</Button>
		)
	}
]

const InvoiceList = () => <DataTable columns={columns} listProps={{ canCreate: false }} actions={{ show: true, del: true }} />

export default InvoiceList
