import { Space } from 'antd'

import LangWrapper from './langWrapper'

export const WrappedLangContent = ({ content, children }) => {
	const lang = LangWrapper.useLang()

	return (
		<Space direction='vertical' size='middle'>
			<span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: content?.[lang] }} />
			{children}
		</Space>
	)
}

const LangContent = ({ availableLangs, defaultLang, content, children }) =>
	content && (
		<LangWrapper
			availableLangs={
				availableLangs ||
				Object.entries(content)
					.map(([k, v]) => v && k)
					.filter(Boolean)
			}
			defaultLang={defaultLang}
		>
			<WrappedLangContent content={content}>{children}</WrappedLangContent>
		</LangWrapper>
	)

LangContent.useLang = LangWrapper.useLang

export default LangContent
