import { useForm } from '@refinedev/antd'
import { Button, Modal } from 'antd'

import SchematicForm from './form'

// edit when id is passed, create otherwise
const SchematicModalForm = ({ schema, title, open, onCancel, resource, id, footerButtons, initialValues, useFormParams, ...props }) => {
	const {
		form,
		formProps: { onFinish, ...formProps },
		saveButtonProps
	} = useForm({
		id,
		resource,
		action: id ? 'edit' : 'create',
		onMutationSuccess: () => {
			form.resetFields()
			onCancel()
		},
		...useFormParams
	})

	formProps.onFinish = () => onFinish(form.getFieldsValue(true))

	formProps.initialValues = {
		...formProps.initialValues,
		initialValues
	}

	const footer = [
		...(footerButtons?.map(({ title, ...btnProps }) => (
			<Button key={title} {...btnProps}>
				{title}
			</Button>
		)) || []),
		<Button onClick={onCancel} key='cancel'>
			Cancel
		</Button>,
		<Button {...saveButtonProps} key='ok' type='primary'>
			{id ? 'Edit' : 'Create'}
		</Button>
	]

	return (
		<Modal open={open} forceRender={true} onCancel={onCancel} title={`${id ? 'Edit' : 'Create'} ${title}`} footer={footer} {...props}>
			<SchematicForm {...{ schema, form, formProps: { ...formProps, initialValues: id ? formProps.initialValues : initialValues || {} } }} />
		</Modal>
	)
}

export default SchematicModalForm
