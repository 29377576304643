import { Edit, useForm } from '@refinedev/antd'

import { AgreementForm } from '../../components/agreement/agreementForm'

const AgreementEdit = () => {
	const { formProps, saveButtonProps } = useForm({ redirect: 'show' })

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<AgreementForm {...formProps} />
		</Edit>
	)
}

export default AgreementEdit
