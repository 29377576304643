import { DateField } from '@refinedev/antd'
import { Col, Divider, Row, Typography } from 'antd'

import { DataShow } from '../../components/dataView'

const { Title } = Typography

const InvestorShow = () => (
	<DataShow>
		{record => {
			return (
				<>
					<Row>
						<Col span={4}>Last updated:</Col>
						<Col span={4}>
							<DateField value={record.updated} />
						</Col>
					</Row>
					<Row>
						<Col span={11}>
							<Title level={3}>
								{record.first_name} {record.last_name}
							</Title>
						</Col>
					</Row>
					<Divider />
				</>
			)
		}}
	</DataShow>
)

export default InvestorShow
