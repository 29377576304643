import { useMemo } from 'react'

import { DataTable } from '../../components/dataView'
import { getTableColumns } from './schemaRenders'

const SchematicDataTable = ({ schema, resource, ...props }) => {
	const columns = useMemo(() => getTableColumns(schema), [schema])

	return <DataTable actions={{ del: true, show: true }} resource={resource} columns={columns} {...props} />
}

export default SchematicDataTable
