import { useState, useEffect } from 'react'

import SchematicModalForm from './modalForm'
import SchematicDataTable from './dataTable'

import { getStartCase } from '../../utils/helpers'

const SchematicDataTableWithCreateModal = ({ resource, schema, title, createButtonTitle, actions, editable, modalFormProps, dataTableProps }) => {
	const [isEditorModalOpen, setIsEditorModalOpen] = useState(false)
	const [selectedInvestEventId, setSelectedInvestEventId] = useState()

	useEffect(() => {
		if (!isEditorModalOpen) {
			setSelectedInvestEventId()
		}
	}, [isEditorModalOpen])

	const editHandler = id => {
		setSelectedInvestEventId(id)
		setIsEditorModalOpen(true)
	}

	return (
		<>
			<SchematicModalForm
				{...modalFormProps}
				schema={schema}
				resource={resource}
				open={isEditorModalOpen}
				id={selectedInvestEventId}
				title={title || getStartCase(resource)}
				onCancel={() => setIsEditorModalOpen(false)}
			/>
			<SchematicDataTable
				{...dataTableProps}
				schema={schema}
				resource={resource}
				actionsColumnProps={{ fixed: 'right' }}
				actions={actions || { del: true, show: true }}
				customActions={editable && [{ title: 'Edit', onClick: editHandler }]}
				listProps={{
					title: title || getStartCase(resource),
					createButtonProps: { onClick: () => setIsEditorModalOpen(true), title: createButtonTitle },
					...(dataTableProps?.listProps || {})
				}}
			/>
		</>
	)
}

export default SchematicDataTableWithCreateModal
