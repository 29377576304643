import Icon from '@ant-design/icons'
import { Authenticated, Refine } from '@refinedev/core'
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { ErrorComponent, notificationProvider, ThemedLayout } from '@refinedev/antd'
import routerBindings, { CatchAllNavigate, NavigateToResource, UnsavedChangesNotifier } from '@refinedev/react-router-v6'

import { Header } from './components/header'

import apiProvider from './utils/provider'
import { authProvider } from './utils/auth-provider'
import { axiosInstance, AxiosInterceptor } from './utils/axios-interceptor'

import { Login } from './pages/login'
import { getFlatPagesRoutes, getPagesResources } from './pages'

import { ReactComponent as Logo } from './icons/adgvLogo.svg'

import '@refinedev/antd/dist/reset.css'
import './globals.css'

const pages = getFlatPagesRoutes()
const resources = getPagesResources()
const dataProvider = apiProvider(process.env.REACT_APP_BOT_API_URL, axiosInstance)

const refineOptions = {
	syncWithLocation: true,
	warnWhenUnsavedChanges: true,
	reactQuery: {
		clientConfig: {
			defaultOptions: {
				queries: {
					retry: false
				},
				mutations: {
					retry: false
				}
			}
		}
	},
	disableTelemetry: true
}

const Title = ({ collapsed }) => (
	<div style={{ width: '100%', height: '120px', display: 'flex', justifyContent: 'center' }}>
		{!collapsed && <Icon component={Logo} style={{ filter: 'brightness(0%)', fontSize: 136, marginTop: 4 }} />}
	</div>
)

const App = () => {
	return (
		<BrowserRouter>
			<RefineKbarProvider>
				<Refine
					resources={resources}
					options={refineOptions}
					authProvider={authProvider}
					dataProvider={dataProvider}
					routerProvider={routerBindings}
					notificationProvider={notificationProvider}
				>
					<AxiosInterceptor>
						<Routes>
							<Route
								element={
									<Authenticated fallback={<CatchAllNavigate to='/login' />}>
										<ThemedLayout Header={Header} Title={props => <Title {...props} />}>
											<Outlet />
										</ThemedLayout>
									</Authenticated>
								}
							>
								<Route index element={<NavigateToResource resource='campaigns' />} />
								{pages}
							</Route>
							<Route
								element={
									<Authenticated fallback={<Outlet />}>
										<NavigateToResource />
									</Authenticated>
								}
							>
								<Route path='/login' element={<Login />} />
							</Route>
							<Route
								element={
									<Authenticated>
										<Outlet />
									</Authenticated>
								}
							>
								<Route path='*' element={<ErrorComponent />} />
							</Route>
						</Routes>
						<RefineKbar />
						<UnsavedChangesNotifier />
					</AxiosInterceptor>
				</Refine>
			</RefineKbarProvider>
		</BrowserRouter>
	)
}

export default App
