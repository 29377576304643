import { DateField } from '@refinedev/antd'
import { Col, Divider, Row, Typography } from 'antd'

import { langsByKey } from '../../utils/constants'
import { DataShow } from '../../components/dataView'

const { Title, Paragraph } = Typography

const MeetupShow = () => (
	<DataShow>
		{record => {
			return (
				<>
					<Row>
						<Col span={4}>Last updated:</Col>
						<Col span={4}>
							<DateField value={record.updated} />
						</Col>
						<Col span={2}>{langsByKey[record?.lang]?.label}</Col>
					</Row>
					<Row>
						<Col span={11}>
							<Title level={3}>{record.name}</Title>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<Paragraph>{record.description}</Paragraph>
						</Col>
					</Row>
					<Divider />
				</>
			)
		}}
	</DataShow>
)

export default MeetupShow
