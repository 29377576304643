import { Space, Timeline } from 'antd'
import { useApiUrl, useCustom, useResource } from '@refinedev/core'
import { dealStates } from '../../../utils/options'
import { getStartCase } from '../../../utils/helpers'

const DealTimeline = () => {
	const apiUrl = useApiUrl()
	const { id } = useResource()

	const { data, isLoading } = useCustom({
		url: `${apiUrl}/deals/${id}/timeline`,
		method: 'get'
	})

	return (
		!isLoading &&
		data && (
			<div style={{ width: 200, marginTop: 16 }}>
				<Timeline
					reverse
					mode='left'
					items={data.data
						?.filter(d => d.date)
						.map(({ date, name }) => ({ label: date, children: dealStates[name]?.label || getStartCase(name) }))}
				/>
			</div>
		)
	)
}

const DealSummary = () => {
	return (
		<Space>
			<DealTimeline />
			{/* <div style={{ width: '100%' }}>Summary</div> */}
		</Space>
	)
}

export default DealSummary
