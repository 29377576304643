import { Divider, Space } from 'antd'

import { DataShow } from '../../components/dataView'
import CampaignView from '../../components/campaign/campaignView'
import ActiveButton from '../../components/campaign/buttons/activeButton'
import TestModalButton from '../../components/campaign/buttons/testModalButton'
import CampaignRunButton from '../../components/campaign/buttons/runCampaignButton'
import ExportAudienceButton from '../../components/campaign/buttons/exportAudienceButton'

const CampaignShow = () => {
	return (
		<DataShow>
			{record => {
				const campaignInfo = record && {
					campaignId: record.id,
					campaignName: record.name,
					campaignActive: record.active,
					campaignContext: record.context
				}

				return (
					<>
						<CampaignView camp={record} withHeader withRecipients />
						<Divider />
						{campaignInfo && (
							<Space>
								<TestModalButton {...campaignInfo} />
								<CampaignRunButton {...campaignInfo} />
								<ExportAudienceButton {...campaignInfo} />
								<ActiveButton {...campaignInfo} />
							</Space>
						)}
					</>
				)
			}}
		</DataShow>
	)
}

export default CampaignShow
