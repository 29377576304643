import { Create } from '@refinedev/antd'

import CampaignForm from '../../components/campaign/campaignForm'
import useCampaignFormProps from '../../components/campaign/hooks/useCampaignFormProps'

const CampaignCreate = () => {
	const { resource, form } = useCampaignFormProps({
		initialValues: {
			content: {
				langs: ['en']
			},
			filters: [
				{
					filter_type: 'state',
					exclude: false,
					state: 'approved',
					type: 'current'
				}
			],
			trigger_type: 'manual',
			active: false
		},
		ignoreRecipientsUpdateWithoutCampId: false
	})

	return (
		<Create {...resource}>
			<CampaignForm {...form} />
		</Create>
	)
}

export default CampaignCreate
