import { Row, Form, Select } from 'antd'

import { selectStates } from '../../../utils/constants'
import { DateInput } from '../../inputs'
import FilterInputColumn from './wrappers/filterInputColumn'

const stateTypeOptions = [
	{ label: 'Current', value: 'current' },
	{ label: 'Reached', value: 'reached' }
]

const StateFilter = ({ itemProps, pathName }) => {
	const form = Form.useFormInstance()
	const field = Form.useWatch(['filters', ...pathName, 'state'], form)
	const type = Form.useWatch(['filters', ...pathName, 'type'], form)

	return (
		<Row gutter={[16, 16]}>
			<FilterInputColumn title='State' span={6}>
				<Form.Item {...itemProps} name={[...pathName, 'state']}>
					<Select options={selectStates} />
				</Form.Item>
			</FilterInputColumn>
			{field && (
				<FilterInputColumn title='Type' span={3}>
					<Form.Item {...itemProps} name={[...pathName, 'type']}>
						<Select options={stateTypeOptions} />
					</Form.Item>
				</FilterInputColumn>
			)}
			{type === 'reached' && (
				<>
					<FilterInputColumn title='Date from'>
						<DateInput {...itemProps} name={[...pathName, 'date_from']} placeholder={null} />
					</FilterInputColumn>
					<FilterInputColumn title='Date to'>
						<DateInput {...itemProps} name={[...pathName, 'date_to']} placeholder={null} />
					</FilterInputColumn>
				</>
			)}
		</Row>
	)
}

export default StateFilter
