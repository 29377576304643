import { v4 } from 'uuid'
import { Create, useStepsForm } from '@refinedev/antd'

import DealForm from '../../components/deals/dealForm'

import { CONTENT_ITEM_NAME } from '../../components/deals/steps/content'

const initialContentBlocksNames = [
	'Project description',
	'Team with Linkedin links',
	'Investment Thesis',
	'Market',
	'Plans and Goals',
	'Round Conditions',
	'Commitment deadline',
	'Reporting conditions'
]

const initialContentFields = {
	announcement_blocks: ['Project description', 'Team with Linkedin links']
}

function InitContentBlock(name) {
	this.id = v4()
	this.name = name
}

const getCreateInitialValues = initialValues => {
	const contentBlocks = initialContentBlocksNames.map(name => new InitContentBlock(name))
	const contentBlocksIdsByNames = contentBlocks.reduce((acc, block) => ({ ...acc, [block.name]: block.id }), {})
	const contentFields = Object.entries(initialContentFields).reduce(
		(acc, [field, blocksNames]) => ({
			...acc,
			[field]: blocksNames.map(name => contentBlocksIdsByNames[name])
		}),
		{}
	)

	return {
		[CONTENT_ITEM_NAME]: contentBlocks,
		...contentFields,
		invest_event: {
			type: 'deal'
		},
		...initialValues
	}
}

const DealCreate = () => {
	const { form, formProps, saveButtonProps, onFinish, ...props } = useStepsForm({ redirect: 'show' })

	formProps.onFinish = () => onFinish(form.getFieldsValue(true))

	formProps.initialValues = getCreateInitialValues(formProps.initialValues || {})

	return (
		<Create saveButtonProps={saveButtonProps}>
			<DealForm formProps={formProps} {...props} />
		</Create>
	)
}

export default DealCreate
