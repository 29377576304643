import { useState, useEffect, useCallback } from 'react'
import { useApiUrl, useCustomMutation } from '@refinedev/core'
import { SendOutlined } from '@ant-design/icons'
import { Modal, Button, Space } from 'antd'

import { DealSelector, InvestorSelector } from '../../selectors/entitySelectors'

const TestModal = ({ campaignId, campaignName, campaignContext: { deal_id: contextDealId } = {}, closeModal, ...modalProps }) => {
	const { mutate, isSuccess } = useCustomMutation()
	const apiUrl = useApiUrl()

	const [investorId, setInvestorId] = useState()
	const [dealId, setDealId] = useState()

	const testHandler = () => {
		mutate({
			url: `${apiUrl}/campaigns/${campaignId}/run`,
			method: 'post',
			values: {
				inv_id: investorId,
				deal_id: dealId
			},
			successNotification: {
				message: `Test for campaign ${campaignName} started`,
				type: 'success'
			}
		})
	}

	useEffect(() => {
		if (isSuccess) {
			closeModal()
		}
	}, [isSuccess, closeModal])

	useEffect(() => {
		setDealId(contextDealId)
	}, [contextDealId])

	return (
		<Modal
			title={`Test Campaign ${campaignName}`}
			{...modalProps}
			onCancel={closeModal}
			onOk={testHandler}
			okText='Send'
			okButtonProps={{ disabled: !investorId, icon: <SendOutlined /> }}
		>
			<Space direction='vertical' size='middle' style={{ display: 'flex', marginTop: 24 }}>
				<InvestorSelector defaultValue={investorId} onChange={v => setInvestorId(v)} />
				<DealSelector defaultValue={dealId} onChange={v => setDealId(v)} />
			</Space>
		</Modal>
	)
}

const TestModalButton = modalProps => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

	return (
		<>
			<Button type='primary' icon={<SendOutlined />} onClick={() => setIsModalOpen(true)}>
				Test
			</Button>
			<TestModal {...modalProps} open={isModalOpen} closeModal={closeModal} />
		</>
	)
}

export default TestModalButton
