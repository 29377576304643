import { CameraOutlined } from '@ant-design/icons'

import { makeAllFieldsRequired } from '../../components/schematicComponents/utils'

const unnecessaryFields = []

const schema = makeAllFieldsRequired(
	{
		id: {
			showInputWhen: false,
			renderType: 'itemImage',
			title: <CameraOutlined />,
			renderSchema: { path: '/investors', field: 'avatar' }
		},
		first_name: {},
		last_name: {},
		tg_username: { title: 'Username', renderType: 'tgUsername' },
		email: {},
		phone: { renderType: 'phone' },
		created: { renderType: 'date', showInputWhen: false, sorter: {} },
		updated: { renderType: 'date', showInputWhen: false, sorter: {} }
	},
	unnecessaryFields
)

export default schema
