import { Button, Col, Divider, Row } from 'antd'
import { DateField, TextField } from '@refinedev/antd'

import { DataShow } from '../../components/dataView'

const InvoiceShow = () => {
	return (
		<DataShow>
			{record => {
				return (
					<>
						<Row>
							<Col span={4}>Created:</Col>
							<Col span={4}>
								<DateField value={record.created} />
							</Col>
							<Col span={4}>Number:</Col>
							<Col span={4}>
								<TextField value={record.number} />
							</Col>
							<Col span={4}>Period:</Col>
							<Col span={4}>
								<TextField value={record.period} />
							</Col>
						</Row>
						<Divider />
						<Row>
							<Col span={4}>Amount:</Col>
							<Col span={4}>
								<TextField value={record.amount} />
							</Col>
							<Col span={4}>
								<Button type='primary' href={record.url}>
									Pay
								</Button>
							</Col>
						</Row>
					</>
				)
			}}
		</DataShow>
	)
}

export default InvoiceShow
