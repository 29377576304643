import { Row, Form, InputNumber } from 'antd'

import { DealSelector } from '../../selectors/entitySelectors'
import { SyndSelector } from '../../selectors/querySelectors'
import { DateInput } from '../../inputs'

import FilterInputColumn from './wrappers/filterInputColumn'

const DealFilter = ({ pathName, itemProps }) => (
	<Row gutter={[16, 16]}>
		<FilterInputColumn title='Deal' span={6}>
			<Form.Item {...itemProps} name={[...pathName, 'deal_id']}>
				<DealSelector placeholder={null} allowClear />
			</Form.Item>
		</FilterInputColumn>
		<FilterInputColumn title='Syndicator' span={6}>
			<Form.Item {...itemProps} name={[...pathName, 'synd_legal_entity_id']}>
				<SyndSelector placeholder={null} allowClear />
			</Form.Item>
		</FilterInputColumn>
		<FilterInputColumn title='Date from' span={3}>
			<DateInput {...itemProps} name={[...pathName, 'date_from']} placeholder={null} />
		</FilterInputColumn>
		<FilterInputColumn title='Date to' span={3}>
			<DateInput {...itemProps} name={[...pathName, 'date_to']} placeholder={null} />
		</FilterInputColumn>
		<FilterInputColumn title='Min amount' span={3}>
			<Form.Item {...itemProps} name={[...pathName, 'min']}>
				<InputNumber addonBefore='$' min={0} style={{ width: '100%' }} />
			</Form.Item>
		</FilterInputColumn>
		<FilterInputColumn title='Max amount' span={3}>
			<Form.Item {...itemProps} name={[...pathName, 'max']}>
				<InputNumber addonBefore='$' min={0} style={{ width: '100%' }} />
			</Form.Item>
		</FilterInputColumn>
	</Row>
)

export default DealFilter
