import { Card, Col, Row } from 'antd'

import { RemoveButton } from '../../buttons'

const ListCard = ({ children, HeaderCols, remove, name, ...headerProps }) => {
	return (
		<Card style={{ marginBottom: 24 }}>
			<Row gutter={[16, 16]} justify='start'>
				{HeaderCols && <HeaderCols name={name} {...headerProps} />}
				<Col flex='auto'>
					<Row justify='end'>
						<RemoveButton onClick={() => remove(name)} />
					</Row>
				</Col>
			</Row>
			{children}
		</Card>
	)
}

export default ListCard
