import { Create, useForm } from '@refinedev/antd'

import { AgreementForm } from '../../components/agreement/agreementForm'

const AgreementCreate = () => {
	const { formProps, saveButtonProps } = useForm({ redirect: 'show' })
	formProps.initialValues = { ...formProps.initialValues }

	return (
		<Create saveButtonProps={saveButtonProps}>
			<AgreementForm {...formProps} />
		</Create>
	)
}

export default AgreementCreate
