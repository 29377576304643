import { Col, Form, InputNumber, Row, Select } from 'antd'

import { ListEdit } from '../../listEdit'
import ListCard from '../wrappers/listCard'
import { DealSelector } from '../../selectors/entitySelectors'

export const eventTypes = [
	{
		label: 'Investor total invested amount change',
		value: 'investor_total_invested_changed'
	},
	{
		label: 'Invested to deal',
		value: 'investment_completed'
	},
	{
		label: 'Coin balance changed',
		value: 'coin_balance_changed'
	},
	{
		label: 'Collected percentage of allocation changed',
		value: 'deal_collected_changed'
	},
	{
		label: 'Deal became visible',
		value: 'deal_became_visible'
	}
]

const MoneyAmount = ({ name, itemProps, label, field, defaultValue, min, max, addonBefore }) => (
	<Form.Item label={label} name={name.concat([field])} {...itemProps} labelCol={{ span: 0 }}>
		<InputNumber addonBefore={addonBefore || '$'} defaultValue={defaultValue} min={min} max={max} style={{ width: '100%' }} />
	</Form.Item>
)

const LowWater = props => <MoneyAmount label='Below' field='low' {...props} />

const HighWater = props => <MoneyAmount label='Above' field='high' min={0} {...props} />

const DealInput = ({ name, itemProps }) => (
	<Form.Item label='Deal' name={name.concat(['deal_id'])} {...itemProps} labelCol={{ span: 0 }}>
		<DealSelector />
	</Form.Item>
)

const InvestorTotalInvestedChangedTrigger = props => (
	<Col span={8}>
		<HighWater {...props} />
	</Col>
)

const InvestmentCompletedTrigger = props => (
	<Col span={8}>
		<DealInput {...props} />
	</Col>
)

const CoinBalanceChangedTrigger = ({ name, ...props }) => {
	const form = Form.useFormInstance()
	const changeType = Form.useWatch(['trigger', 'events', name, 'change_type'], form)

	return (
		<>
			<Col span={8}>
				<Form.Item label='Type' name={[name, 'change_type']} labelCol={4}>
					<Select
						options={[
							{ label: 'Threshold', value: 'threshold' },
							{ label: 'Change', value: 'change' }
						]}
						defaultValue='threshold'
					/>
				</Form.Item>
			</Col>
			{changeType === 'change' ? (
				<>
					<Col span={8}>
						<Form.Item label='Direction' name={[name, 'direction']} labelCol={4}>
							<Select
								options={[
									{ label: 'Up', value: 'up' },
									{ label: 'Down', value: 'down' }
								]}
							/>
						</Form.Item>
					</Col>
				</>
			) : (
				<>
					<Col span={8}>
						<LowWater {...props} name={name} addonBefore='ADC' />
					</Col>
					<Col span={8}>
						<HighWater {...props} name={name} addonBefore='ADC' />
					</Col>
				</>
			)}
		</>
	)
}

const DealCollectedChangedTrigger = ({ name, itemProps }) => (
	<>
		<Col span={8}>
			<DealInput name={name} itemProps={itemProps} />
		</Col>
		<Col span={8}>
			<Form.Item label='Allocation collected' name={name.concat(['high'])} labelCol={{ span: 0 }} {...itemProps}>
				<InputNumber defaultValue={100} low={0} max={100} addonAfter='%' />
			</Form.Item>
		</Col>
	</>
)

const DealBecameVisibleTrigger = props => (
	<Col span={8}>
		<DealInput {...props} />
	</Col>
)

const triggerInputs = {
	investor_total_invested_changed: InvestorTotalInvestedChangedTrigger,
	investment_completed: InvestmentCompletedTrigger,
	coin_balance_changed: CoinBalanceChangedTrigger,
	deal_collected_changed: DealCollectedChangedTrigger,
	deal_became_visible: DealBecameVisibleTrigger
}

const EventTriggerCardHeaderCols = ({ name, itemProps }) => (
	<>
		<Col span={8}>
			<Form.Item name={[name, 'type']} labelCol={{ span: 0 }} {...itemProps}>
				<Select placeholder='Event' options={eventTypes} />
			</Form.Item>
		</Col>
	</>
)

const EventTrigger = ({ name, itemProps, remove }) => {
	const form = Form.useFormInstance()
	const eventType = Form.useWatch(['trigger', 'events', name, 'type'], form)
	const InputRenderer = triggerInputs[eventType]

	return (
		<ListCard HeaderCols={EventTriggerCardHeaderCols} name={name} remove={remove} itemProps={itemProps}>
			{InputRenderer && (
				<Row gutter={[16, 16]} justify='start'>
					<InputRenderer name={[name]} itemProps={itemProps} />
				</Row>
			)}
		</ListCard>
	)
}

const EventTriggers = () => (
	<ListEdit name={['trigger', 'events']} addLabel='Add event'>
		<EventTrigger />
	</ListEdit>
)

export default EventTriggers
