import { useState, useEffect } from 'react'
import { Form, Typography, Space, Segmented, Checkbox } from 'antd'
import _ from 'lodash'

import CtaActions from '../editors/ctaActions'
import { TGTextEdit } from '../../i18text-edit'
import { langs } from '../../../utils/constants'

const { Title } = Typography

const Content = () => {
	const form = Form.useFormInstance()
	const formActions = Form.useWatch(['content', 'actions'], form)

	const [lang, setLang] = useState('en')
	const [checkedLangs, setCheckedLangs] = useState({})
	const [multipleAnswersAbility, setMultipleAnswersAbility] = useState(true)

	useEffect(() => {
		if (!formActions) return
		const allActionsAreAnswers = formActions.every(a => !a?.type || a.type === 'quiz_answer')
		setMultipleAnswersAbility(allActionsAreAnswers)
		if (!allActionsAreAnswers) {
			form.setFieldValue(['content', 'multiple_answers'], false)
		}
	}, [form, formActions])

	useEffect(() => {
		const formLangs = form.getFieldValue(['content', 'langs']) || []
		setCheckedLangs(formLangs.reduce((acc, l) => ({ ...acc, [l]: true }), {}))
	}, [form])

	useEffect(() => {
		const formLangs = _.keys(_.pickBy(checkedLangs))
		form.setFieldValue(['content', 'langs'], formLangs)
	}, [form, setCheckedLangs, checkedLangs])

	return (
		<>
			<Space direction='vertical' size='small' style={{ display: 'flex' }}>
				<Title level={3}>Content</Title>
				<Segmented
					options={langs.map(l => ({
						label: (
							<Space>
								{l.labeled_text}
								<Checkbox
									checked={checkedLangs[l.key]}
									onChange={e => setCheckedLangs({ ...checkedLangs, [l.key]: e.target.checked })}
								/>
							</Space>
						),
						value: l.key
					}))}
					onChange={l => setLang(l)}
					default={lang}
				/>
				<Form.Item label='Message' name={['content', 'message_text', lang]} labelCol={{ span: 24 }}>
					<TGTextEdit />
				</Form.Item>
			</Space>
			<Title level={3}>Actions</Title>
			<Form.Item label='Multiple answers' labelCol={1} name={['content', 'multiple_answers']} valuePropName='checked'>
				<Checkbox disabled={!multipleAnswersAbility} />
			</Form.Item>
			<CtaActions lang={lang} />
		</>
	)
}

export default Content
