import { Row, Form } from 'antd'

import FilterInputColumn from './wrappers/filterInputColumn'
import { CampaignSelector } from '../../selectors/entitySelectors'
import { CampaignAnswerSelector } from '../../selectors/querySelectors'

const CampaignFilter = ({ pathName, itemProps }) => {
	const form = Form.useFormInstance()
	const campaign_id = Form.useWatch(['filters', ...pathName, 'campaign_id'], form)

	return (
		<Row gutter={[16, 16]}>
			<FilterInputColumn title='Campaign' span={6}>
				<Form.Item {...itemProps} name={[...pathName, 'campaign_id']}>
					<CampaignSelector placeholder={null} allowClear />
				</Form.Item>
			</FilterInputColumn>
			{campaign_id && (
				<FilterInputColumn title='Answers' span={12}>
					<Form.Item {...itemProps} name={[...pathName, 'answers']}>
						<CampaignAnswerSelector mode='multiple' campaign_id={campaign_id} />
					</Form.Item>
				</FilterInputColumn>
			)}
		</Row>
	)
}

export default CampaignFilter
