import dayjs from 'dayjs'

const schema = {
	project_id: { renderType: 'project', title: 'Project', required: true },
	material_url: { renderType: 'langUrl' },
	syndicator_summary: { renderType: 'textArea' },
	valuation: { renderType: 'textArea' },
	investment_events: { renderType: 'textArea' },
	cash_in_the_bank: { renderType: 'textArea' },
	burn_rate: { renderType: 'textArea' },
	run_way: { renderType: 'textArea' },
	revenue: { renderType: 'textArea' },
	ebitda: { renderType: 'textArea' },
	mrr: { renderType: 'textArea' },
	key_metrics: { renderType: 'textArea' },
	created: {
		renderType: 'time',
		sorter: {},
		formItemProps: {
			getValueProps: value => ({ value: value ? dayjs(value) : null }),
			getValueFromEvent: data => (data ? dayjs(data).format('YYYY-MM-DD HH:mm:ss') : null)
		}
	}
}

export default schema
