import React from 'react'
import ReactQuill from 'react-quill'
import * as Emoji from 'quill-emoji'
import { createRoot } from 'react-dom/client'

import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'

import App from './App'

const { Quill } = ReactQuill

Quill.register('modules/emoji', Emoji)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
