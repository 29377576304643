import { Button } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useResource, useApiUrl, useCustomMutation, useGo } from '@refinedev/core'

export const AddButton = ({ onClick, children }) => (
	<Button icon={<PlusOutlined />} style={{ maxWidth: '10em' }} onClick={onClick}>
		{children}
	</Button>
)

export const RemoveButton = ({ onClick }) => <Button danger onClick={onClick} icon={<DeleteOutlined />} />

export const CopyButton = ({ recordItemId, ...props }) => {
	const { resource } = useResource()
	const apiUrl = useApiUrl()
	const go = useGo()

	const { mutate } = useCustomMutation()

	const clickHandler = () => {
		mutate(
			{
				url: `${apiUrl}/${resource.name}/${recordItemId}/copy`,
				method: 'post'
			},
			{
				onSuccess: data =>
					go({
						to: `/${resource.name}/show/${data.data}`,
						type: 'push'
					})
			}
		)
	}

	return (
		<Button onClick={clickHandler} {...props}>
			Copy
		</Button>
	)
}
