import { Edit, useForm } from '@refinedev/antd'
import { Card, Col, Form, Input, Row } from 'antd'

import { getStartCase } from '../../utils/helpers'

import LangWrapper from '../../containers/langWrapper'
import { TGTextEdit } from '../../components/i18text-edit'
import { IndustrySelector } from '../../components/selectors/entitySelectors'

const { Item } = Form

const ProjectFormItem = ({ name, label, children, ...props }) => (
	<Item name={name} label={label || getStartCase(name)} labelCol={{ span: 6 }} labelAlign='left' {...props}>
		{children}
	</Item>
)

const DescriptionsInputs = () => {
	const lang = LangWrapper.useLang()

	return (
		<>
			<Item name={['desc', lang]} label='Description' labelCol={{ span: 24 }}>
				<TGTextEdit />
			</Item>
			<Item name={['short_desc', lang]} label='Short Description' labelCol={{ span: 24 }}>
				<TGTextEdit />
			</Item>
		</>
	)
}

const DescriptionsCard = () => (
	<Card>
		<LangWrapper>
			<DescriptionsInputs />
		</LangWrapper>
	</Card>
)

const ProjectEdit = () => {
	const { form, formProps, saveButtonProps, onFinish } = useForm()

	const handleOnFinish = () => onFinish(form.getFieldsValue(true)) // to save preserved (desc with langs) fields

	return (
		<Edit resource='projects' saveButtonProps={saveButtonProps}>
			<Form {...formProps} onFinish={handleOnFinish}>
				<Row gutter={32}>
					<Col span={12}>
						<ProjectFormItem name='name' rules={[{ required: true }]}>
							<Input />
						</ProjectFormItem>
					</Col>
					<Col span={12}>
						<ProjectFormItem name='old_name'>
							<Input />
						</ProjectFormItem>
					</Col>
				</Row>
				<Row gutter={32}>
					<Col span={12}>
						<ProjectFormItem name='website'>
							<Input />
						</ProjectFormItem>
					</Col>
					<Col span={12}>
						<ProjectFormItem name='target_market'>
							<Input />
						</ProjectFormItem>
					</Col>
				</Row>
				<ProjectFormItem name='industry_ids' label='Industries' labelCol={{ span: 3 }}>
					<IndustrySelector />
				</ProjectFormItem>
				<DescriptionsCard />
			</Form>
		</Edit>
	)
}

export default ProjectEdit
