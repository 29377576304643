import { DatePicker, Form, Input, InputNumber } from 'antd'
import dayjs from 'dayjs'
import LangWrapper from '../containers/langWrapper'

export const DateInput = ({ name, placeholder, ...props }) => (
	<Form.Item
		name={name}
		getValueProps={value => ({
			value: value ? dayjs(value) : null
		})}
		getValueFromEvent={data => {
			return data ? dayjs(data).format('YYYY-MM-DD') : null
		}}
		{...props}
	>
		<DatePicker defaultPickerValue={dayjs()} placeholder={placeholder} mode='date' format='DD-MM-YYYY' style={{ width: '100%' }} />
	</Form.Item>
)

export const PercentInput = props => (
	<InputNumber formatter={value => value * 100} parser={value => Number(value.replace('%', '')) / 100} addonAfter='%' {...props} />
)

const LangUrlFormInput = ({ value, onChange }) => {
	const lang = LangWrapper.useLang()

	return <Input value={value?.[lang]} onChange={e => onChange({ ...value, [lang]: e.target.value })} />
}

export const LangUrl = props => (
	<LangWrapper>
		<LangUrlFormInput {...props} />
	</LangWrapper>
)
