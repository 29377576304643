import { Space, Descriptions } from 'antd'

import { formatCurrency } from '../../dataView'
import { SchematicDataTableWithCreateModal } from '../../schematicComponents'

import { dealFormInvestEventSchema } from '../../../pages/investEvents/schema'

const { Item: DescItem } = Descriptions

const DealOffer = ({ record }) => (
	<Space direction='vertical' style={{ width: '100%' }}>
		<Descriptions bordered>
			<DescItem label='Allocation'>{formatCurrency(record.allocation)}</DescItem>
			<DescItem label='Syndicators'>{record.syndicators?.join(', ')}</DescItem>
		</Descriptions>
		<SchematicDataTableWithCreateModal
			editable
			title='Invest Event'
			resource='invest_events'
			schema={dealFormInvestEventSchema}
			modalFormProps={{
				initialValues: {
					deal_id: record.id,
					type: 'deal'
				},
				useFormParams: {
					redirect: false
				}
			}}
			dataTableProps={{
				filters: {
					permanent: [
						{
							field: 'deal_id',
							operator: 'eq',
							value: record.id
						}
					]
				},
				listProps: {
					breadcrumb: false,
					title: 'Invest Events'
				}
			}}
		/>
	</Space>
)

export default DealOffer
