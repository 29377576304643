import { Form, Row, Col, Typography, Select } from 'antd'

const week_days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const format_num = (number, digits = 2) => number.toLocaleString('en-US', { minimumIntegerDigits: digits, useGrouping: false })

const CronTrigger = () => (
	<Row gutter={[16, 16]}>
		<Col span={2}>
			<Typography.Paragraph>Year: </Typography.Paragraph>
			<Form.Item name={['trigger', 'cron', 'year']}>
				<Select
					options={[...Array(5).keys()].map(i => ({
						label: format_num(i + new Date().getFullYear()),
						value: i + new Date().getFullYear()
					}))}
					allowClear
				/>
			</Form.Item>
		</Col>
		<Col span={2}>
			<Typography.Paragraph>Month: </Typography.Paragraph>
			<Form.Item name={['trigger', 'cron', 'month']}>
				<Select options={[...Array(12).keys()].map(i => ({ label: i + 1, value: i + 1 }))} allowClear />
			</Form.Item>
		</Col>
		<Col span={2}>
			<Typography.Paragraph>Day: </Typography.Paragraph>
			<Form.Item name={['trigger', 'cron', 'day']}>
				<Select options={[...Array(31).keys()].map(i => ({ label: i + 1, value: i + 1 }))} allowClear />
			</Form.Item>
		</Col>
		<Col span={2}>
			<Typography.Paragraph>Week: </Typography.Paragraph>
			<Form.Item name={['trigger', 'cron', 'day']}>
				<Select options={[...Array(53).keys()].map(i => ({ label: i + 1, value: i + 1 }))} allowClear />
			</Form.Item>
		</Col>
		<Col span={4}>
			<Typography.Paragraph>Day of week: </Typography.Paragraph>
			<Form.Item name={['trigger', 'cron', 'day_of_week']}>
				<Select options={[...Array(7).keys()].map(i => ({ label: week_days[i], value: i + 1 }))} allowClear />
			</Form.Item>
		</Col>
		<Col span={4}>
			<Typography.Paragraph>Hour: </Typography.Paragraph>
			<Form.Item name={['trigger', 'cron', 'hour']}>
				<Select options={[...Array(23).keys()].map(i => ({ label: format_num(i + 1), value: i + 1 }))} allowClear />
			</Form.Item>
		</Col>
		<Col span={4}>
			<Typography.Paragraph>Minute: </Typography.Paragraph>
			<Form.Item name={['trigger', 'cron', 'minute']}>
				<Select options={[...Array(60).keys()].map(i => ({ label: format_num(i + 1), value: i + 1 }))} allowClear />
			</Form.Item>
		</Col>
		<Col span={4}>
			<Typography.Paragraph>Second: </Typography.Paragraph>
			<Form.Item name={['trigger', 'cron', 'second']}>
				<Select options={[...Array(60).keys()].map(i => ({ label: format_num(i + 1), value: i + 1 }))} allowClear />
			</Form.Item>
		</Col>
	</Row>
)

const cronTriggerInfoFields = {
	day: 'Day',
	day_of_week: 'Day of week',
	hour: 'Hour',
	minute: 'Min',
	month: 'Month',
	second: 'Sec',
	week: 'Week',
	year: 'Year'
}

export const getCronTriggerInfo = info =>
	Object.entries(info)
		.filter(([_, value]) => value)
		.map(([key, value]) => cronTriggerInfoFields[key] + ': ' + value)
		.join(', ')

export default CronTrigger
