import { v4 } from 'uuid'
import { memo, useState, useEffect } from 'react'
import { CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Space, Tabs, Input, Card, Button, Row, Col, Select, Typography } from 'antd'

import { TGTextEdit } from '../../i18text-edit'
import LangWrapper from '../../../containers/langWrapper'
import DraggableContentTagsColumn from '../draggableContentTagsColumn'

import { getStartCase } from '../../../utils/helpers'
import { constructorContentFields, constructorContentFieldsLabels } from '../../../pages/deals'

const { Item } = Form
const { Text } = Typography

export const CONTENT_ITEM_NAME = 'content'

const EditableBlock = ({ id, ind, lang, copyCurrentBlock }) => {
	const form = Form.useFormInstance()

	const removeBlock = () =>
		form.setFieldValue(
			CONTENT_ITEM_NAME,
			form.getFieldValue(CONTENT_ITEM_NAME).filter(b => b.id !== id)
		)

	return (
		<Card size='small'>
			<Row gutter={[16, 16]} justify='space-between'>
				<Col flex='auto'>
					<Item
						label='Name'
						labelAlign='left'
						labelCol={{ span: 2 }}
						style={{ width: '100%' }}
						name={[CONTENT_ITEM_NAME, ind, 'name']}
						rules={[{ required: true, message: 'Block name is required' }]}
					>
						<Input style={{ width: '50%' }} />
					</Item>
				</Col>
				<Col>
					<Space.Compact>
						<Button onClick={copyCurrentBlock}>
							<CopyOutlined />
						</Button>
						<Button danger onClick={removeBlock}>
							<DeleteOutlined />
						</Button>
					</Space.Compact>
				</Col>
			</Row>
			<Item name={[CONTENT_ITEM_NAME, ind, 'content', lang]} labelCol={{ span: 24 }} style={{ margin: 0 }}>
				<TGTextEdit />
			</Item>
		</Card>
	)
}

const BlocksEditor = memo(
	({ blocksIds }) => {
		const lang = LangWrapper.useLang()
		const form = Form.useFormInstance()

		const createBlock = (block = {}) =>
			form.setFieldValue(CONTENT_ITEM_NAME, [...(form.getFieldValue(CONTENT_ITEM_NAME) || []), { ...block, id: v4() }])

		const copyBlock = id => {
			const blockToCopy = form.getFieldValue(CONTENT_ITEM_NAME).find(b => b.id === id)
			createBlock({ ...blockToCopy, name: `${blockToCopy.name} (copy)` })
		}

		return (
			<Space direction='vertical' size='middle' style={{ width: '100%' }}>
				{blocksIds.map((id, ind) => (
					<EditableBlock key={id} id={id} ind={ind} lang={lang} copyCurrentBlock={() => copyBlock(id)} />
				))}
				<Button type='primary' onClick={() => createBlock()} icon={<PlusOutlined />}>
					Add block
				</Button>
			</Space>
		)
	},
	(prevProps, nextProps) => prevProps.blocksIds.length === nextProps.blocksIds.length
)

const Blocks = () => {
	const blocks = Form.useWatch([CONTENT_ITEM_NAME], { preserve: true }) || []
	const blocksIds = blocks.map(b => b.id)

	return (
		<LangWrapper>
			<BlocksEditor blocksIds={blocksIds} />
		</LangWrapper>
	)
}

const ContentTagsCard = ({ title, blocks, value, onChange }) => {
	const form = Form.useFormInstance()
	const b = form.getFieldValue(['content'])
	console.log('bbb', b)

	const freeBlocks = blocks.filter(b => b.name && !value?.includes(b.id))

	const [selectedBlock, setSelectedBlock] = useState(freeBlocks.length ? freeBlocks[0]?.id : null)
	const updateSelectedBlock = fromBlocks => setSelectedBlock(fromBlocks.length ? fromBlocks[0].id : null)

	useEffect(() => {
		if (value) onChange(value.filter(v => blocks.some(b => b.id === v)))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const addBlock = () => {
		onChange([...(value || []), selectedBlock])

		const updatedFreeBlocks = freeBlocks.filter(b => b.id !== selectedBlock)
		updateSelectedBlock(updatedFreeBlocks)
	}

	return (
		<Card title={title} size='small'>
			{value && value.length !== 0 && (
				<DraggableContentTagsColumn
					value={value}
					blocks={blocks}
					onChange={onChange}
					onTagClose={id => {
						const updatedFreeBlocks = [...freeBlocks, blocks.find(b => b.id === id)]
						updateSelectedBlock(updatedFreeBlocks)
					}}
				/>
			)}
			{freeBlocks.length !== 0 && (
				<div style={{ display: 'flex', justifyContent: 'flex-end', margin: '16px 8px 0' }}>
					<Space.Compact>
						<Select
							value={selectedBlock}
							style={{ width: 200 }}
							onChange={v => setSelectedBlock(v)}
							options={freeBlocks.map(({ id, name }) => ({ value: id, label: name }))}
						/>
						<Button onClick={selectedBlock ? addBlock : () => {}}>Add</Button>
					</Space.Compact>
				</div>
			)}
		</Card>
	)
}

const Constructor = () => {
	const blocks = Form.useWatch([CONTENT_ITEM_NAME], { preserve: true }) || []

	return blocks.length !== 0 ? (
		<Space direction='vertical' size='large' style={{ width: '100%' }}>
			{constructorContentFields.map(f => (
				<Item key={f} name={[f]} style={{ margin: 0 }}>
					<ContentTagsCard title={constructorContentFieldsLabels[f] || getStartCase(f)} blocks={blocks} />
				</Item>
			))}
		</Space>
	) : (
		<Text>There aren't any blocks</Text>
	)
}

const contentTabs = [
	{
		key: 'blocks',
		label: 'Blocks',
		children: <Blocks />
	},
	{
		key: 'constructor',
		label: 'Constructor',
		children: <Constructor />
	}
]

const Content = () => <Tabs items={contentTabs} destroyInactiveTabPane={true} />

export default Content
