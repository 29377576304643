import dayjs from 'dayjs'
import { Form, Typography, Input, Select, InputNumber, DatePicker, Row, Col } from 'antd'

import { getDefaultTitle } from '.'
import { currenciesOptions } from '../../../utils/options'
import { DealSelector, IndustrySelector, InvestorSelector, LegalEntitySelector, ProjectSelector } from '../../selectors/entitySelectors'
import { LangUrl } from '../../inputs'

const { Item } = Form
const { TextArea } = Input
const { Text } = Typography

const inputsByType = {
	date: props => <DatePicker {...props} format='DD-MM-YYYY' style={{ width: '100%' }} defaultPickerValue={dayjs()} />,
	time: props => <DatePicker {...props} style={{ width: '100%' }} showTime defaultPickerValue={dayjs()} />,
	percent: props => <InputNumber addonBefore='%' {...props} style={{ width: '100%' }} />,
	legalEntity: props => <LegalEntitySelector {...props} style={{ width: '100%' }} />,
	investor: props => <InvestorSelector {...props} style={{ width: '100%' }} />,
	currency: props => <InputNumber {...props} style={{ width: '100%' }} />,
	number: props => <InputNumber style={{ width: '100%' }} {...props} />,
	industries: () => <IndustrySelector style={{ width: '100%' }} />,
	project: props => <ProjectSelector {...props} />,
	textArea: props => <TextArea {...props} />,
	deal: props => <DealSelector {...props} />,
	langUrl: props => <LangUrl {...props} />,
	text: props => <Input {...props} />,
	tag: props => <Select {...props} />
}

const getCurrencyWrapper =
	(field, { currencyInput, currencyInputField } = {}) =>
	({ children }) =>
		(
			<Row gutter={[16, 16]}>
				<Col flex='auto'>{children}</Col>
				{currencyInput && (
					<Col>
						<Item style={{ margin: 0 }} name={currencyInputField || `${field}_currency`}>
							<Select defaultValue='usd' options={currenciesOptions} />
						</Item>
					</Col>
				)}
			</Row>
		)

const itemWrappersByType = {
	currency: getCurrencyWrapper
}

const TitleWrapper = ({ title, children }) => (
	<Row align='center' gutter={[16, 16]} style={{ marginBottom: 24 }}>
		<Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
			<Text>{title}:</Text>
		</Col>
		<Col flex='auto'>{children}</Col>
	</Row>
)
const DefaultWrapper = ({ children }) => <>{children}</>

const getColumnItemWrapper = (field, type, renderSchema) => itemWrappersByType[type]?.(field, renderSchema) || DefaultWrapper
const getColumnInput = (type = 'text', props) => inputsByType[type]?.(props)

const getColumnItem = (
	field,
	{ title, formField, renderType, renderSchema, possibleValues, required, formItemProps = {} },
	itemNamePrefix = []
) => {
	const options = (possibleValues || (renderSchema && Object.keys(renderSchema)))?.map(v => ({
		value: v,
		label: renderSchema[v]?.title || getDefaultTitle(v)
	}))

	const Wrapper = getColumnItemWrapper(field, renderType, renderSchema)

	return (
		<TitleWrapper title={title || getDefaultTitle(field)} required={!!required} key={field}>
			<Wrapper>
				<Item
					rules={[{ required: !!required, message: '' }]}
					name={[...itemNamePrefix, formField || field]}
					key={formField || field}
					style={{ margin: 0 }}
					{...formItemProps}
				>
					{getColumnInput(renderType, options ? { options } : {}) || <Input />}
				</Item>
			</Wrapper>
		</TitleWrapper>
	)
}

const getFormInput = (field, { showInputWhen, ...schemaAdjustments }, { formWatcher, itemNamePrefix }) => {
	if (showInputWhen !== undefined && showInputWhen !== true && formWatcher) {
		if (!showInputWhen) return null

		const { field: showField, includes } = showInputWhen
		const showValue = formWatcher[showField]

		if (!includes.includes(showValue)) {
			return null
		}
	}

	return getColumnItem(field, schemaAdjustments, itemNamePrefix)
}

const getFormInputs = (customSchema, { formWatcher, itemNamePrefix } = {}) =>
	Object.entries(customSchema || {}).map(c => getFormInput(...c, { formWatcher, itemNamePrefix }))

export default getFormInputs
