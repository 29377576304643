import DealCreate from './create'
import DealEdit from './edit'
import DealList from './list'
import DealShow from './show'

export const contentFields = ['desc', 'short_desc', 'announcement', 'short_announcement', 'keypoints', 'conditions', 'offer', 'oneliner']
export const contentFieldsLabels = { desc: 'Description', short_desc: 'Short description' }

export const constructorContentFields = contentFields.map(f => `${f}_blocks`)
// export const constructorContentFields = ['announcement_blocks']
export const constructorContentFieldsLabels = Object.entries(contentFieldsLabels).reduce(
	(acc, [k, v]) => ({ ...acc, [`${k}_blocks`]: `${v} Blocks` }),
	{}
)

const dealsPages = { create: DealCreate, edit: DealEdit, list: DealList, show: DealShow }

export default dealsPages
