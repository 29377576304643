import { Segmented, Space } from 'antd'
import { useState, useContext, createContext } from 'react'

import { langsByKey } from '../utils/constants'

const LangContext = createContext()

const LangWrapper = ({ availableLangs: availLangs, defaultLang: defLang, children }) => {
	const availableLangs = availLangs || Object.keys(langsByKey)
	const defaultLang = defLang || availableLangs[0]

	const [lang, setLang] = useState(defaultLang)

	const getLangOption = l => ({ label: langsByKey[l]?.labeled_text, value: l })
	const options = availableLangs?.map(getLangOption) || []

	return (
		<Space direction='vertical' size='middle' style={{ width: '100%' }}>
			<Segmented options={options} onChange={l => setLang(l)} defaultValue={defaultLang} />
			<LangContext.Provider value={lang}>{children}</LangContext.Provider>
		</Space>
	)
}

LangWrapper.useLang = () => useContext(LangContext)

export default LangWrapper
